var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "missing-data-slide-component" } },
    [
      _vm.showFiling
        ? [
            _c("slide-heading", { attrs: { heading: _vm.slide.heading } }),
            _vm.schema?.length && _vm.hasVisibleFields
              ? _c("item-requiring-attention", {
                  attrs: { "schema-loaded": true },
                  on: {
                    "no-visible-fields": function ($event) {
                      _vm.hasVisibleFields = false
                    },
                  },
                })
              : _c("div", [
                  _c("p", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n        Looks like there is no additional data that is needed\n      "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "buttons-div" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "button-back",
                          attrs: {
                            variant: "default",
                            "aria-label": "back button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("previous-slide")
                            },
                          },
                        },
                        [
                          _c("fa-icon", {
                            staticClass: "fa-xs",
                            attrs: { icon: "chevron-left" },
                          }),
                          _vm._v(" Back\n        "),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "next-button",
                          attrs: {
                            variant: "primary",
                            "aria-label": "continue button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("next-slide")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "px-3" }, [
                            _vm._v("\n            Continue\n          "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
          ]
        : _c(
            "div",
            { staticClass: "loading-slide" },
            [
              _c("slide-heading", {
                attrs: {
                  heading:
                    "Let’s review and see if we'll need any other information...",
                },
              }),
              _c("p", [
                _vm._v(
                  "Reviewing data... checking inefficient agency bureaucracy..."
                ),
              ]),
              _c("ct-fake-loading-bar", {
                staticClass: "loading-bar",
                attrs: { "actually-complete": _vm.loaded },
                on: {
                  "progress-bar-complete": function ($event) {
                    _vm.showFiling = true
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "buttons-div" },
                [
                  _vm.loaded
                    ? _c(
                        "b-button",
                        {
                          staticClass: "button-back",
                          attrs: {
                            variant: "default",
                            "aria-label": "back button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("previous-slide")
                            },
                          },
                        },
                        [
                          _c("fa-icon", {
                            staticClass: "fa-xs",
                            attrs: { icon: "chevron-left" },
                          }),
                          _vm._v(" Back\n      "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }